import { useEffect, useState } from "react";
import { useScroll } from "./UseScroll";
import { useScrollPosition } from "./UseScrollPosition";

export const useFlex = (
  initialVisible: boolean,
  elemHeight: number,
  threshold: number
) => {
  const [visible, setVisible] = useState(initialVisible);
  const scroll = useScroll();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    if (visible) {
      if (scrollPosition >= elemHeight && scroll > 0) {
        setVisible(false);
      }
    } else {
      if (scrollPosition < elemHeight || scroll < -threshold) {
        setVisible(true);
      }
    }
  }, [elemHeight, scroll, scrollPosition, threshold, visible]);

  return visible;
};
