import { useState, useEffect } from "react";

export const useScrollPosition = () => {
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const updateScrollPos = () => {
      setScrollPos(window.scrollY);
    };
    window.addEventListener("scroll", updateScrollPos);

    return () => window.removeEventListener("scroll", updateScrollPos);
  }, []);

  return scrollPos;
};
