import React, { FC } from "react";
import "./TakujiPage.scss";
import AppLayout from "../../components/AppLayout/AppLayout";

import img1 from "../../img/1-1.png";
import img2 from "../../img/1-2.png";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TakujiPageProps {}

const TakujiPage: FC<TakujiPageProps> = () => (
  <AppLayout>
    <img className="content" src={img1} alt="託児コンテンツ1" />
    <img className="content" src={img2} alt="託児コンテンツ2" />
  </AppLayout>
);

export default TakujiPage;
