import { useCallback, useMemo, useState } from "react";
import { ActionStatus, ActionError, DoActionOptions, Action } from "../types";
import { ensureIsActionError } from "../ErrorUtils";

export const useActionStatus = <A extends string[] | readonly string[]>(
  _actions: A
) => {
  const [entitiesState, setEntitiesState] = useState(
    {} as { [key: string]: ActionStatus<A> | undefined }
  );
  const updateStatus = useCallback((key: string, status: ActionStatus<A>) => {
    setEntitiesState((base) => ({ ...base, [key]: status }));
  }, []);
  const startAction = useCallback(
    (key: string, action: A[number]) => {
      updateStatus(key, { action, inProgress: true });
    },
    [updateStatus]
  );
  const finishAction = useCallback(
    (key: string, action: A[number], error?: ActionError) => {
      updateStatus(key, { action, inProgress: false, error });
    },
    [updateStatus]
  );

  const doAction = useCallback(
    async (
      key: string,
      action: A[number],
      callback: Action,
      options?: DoActionOptions
    ) => {
      startAction(key, action);
      try {
        await callback();
        finishAction(key, action);
      } catch (e) {
        const actionError = ensureIsActionError(e);
        finishAction(key, action, actionError);
        if (!options?.preventException) {
          throw actionError;
        }
      }
    },
    [finishAction, startAction]
  );

  return useMemo(
    () => ({ entitiesState, startAction, finishAction, doAction }),
    [doAction, entitiesState, finishAction, startAction]
  );
};
