import React, { FC, ReactNode } from "react";
import "./InnerBorderBox.scss";

interface InnerBorderBoxProps {
  borderPadding?: number;
  borderWeight?: number;
  borderColor?: string;
  backgroundColor: string;
  children: ReactNode;
}

const InnerBorderBox: FC<InnerBorderBoxProps> = ({
  backgroundColor,
  borderColor,
  borderPadding,
  borderWeight,
  children,
}) => (
  <div
    style={{
      padding: borderPadding ?? 4,
      backgroundColor,
      borderRadius: "5px",
    }}
  >
    <div
      style={{
        border: `solid ${borderWeight ?? 2}px ${borderColor ?? "white"}`,
        borderRadius: "5px",
      }}
      className="p-2"
    >
      {children}
    </div>
  </div>
);

export default InnerBorderBox;
