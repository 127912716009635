import { useState, useEffect, useRef } from "react";

export const useScroll = () => {
  const [scroll, setScroll] = useState(0);
  const scrollPosRef = useRef(0);

  useEffect(() => {
    const updateScrollPos = () => {
      const prev = scrollPosRef.current;
      scrollPosRef.current = window.scrollY;
      setScroll(scrollPosRef.current - prev);
    };
    window.addEventListener("scroll", updateScrollPos);

    return () => window.removeEventListener("scroll", updateScrollPos);
  }, []);

  return scroll;
};
