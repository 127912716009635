import React, { FC } from "react";
import "./QaPage.scss";
import AppLayout from "../../components/AppLayout/AppLayout";
import InnerBorderBox from "../../components/InnerBorderBox/InnerBorderBox";
import { Container } from "react-bootstrap";

import img1 from "../../img/qa.png";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface QaPageProps {}

const QaPage: FC<QaPageProps> = () => {
  const qas = [
    {
      q: "準備中...",
      a: "準備中...",
    },
  ];

  return (
    <AppLayout>
      <img src={img1} className="content" alt="よくある質問ヘッダ" />
      <Container>
        {qas.map((qa, index) => (
          <InnerBorderBox backgroundColor="#D1D7E3" key={index}>
            <p>
              Q{index + 1} {qa.q}
            </p>
            <p>
              A{index + 1} {qa.a}
            </p>
          </InnerBorderBox>
        ))}
      </Container>
    </AppLayout>
  );
};

export default QaPage;
