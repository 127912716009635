import React, { FC } from "react";
import styles from "./MainPage.module.scss";
import AppLayout from "../../components/AppLayout/AppLayout";

import img1 from "../../img/top1.png";
import img2 from "../../img/top2.png";
import img3 from "../../img/top3.png";
import { Link } from "react-router-dom";

import qaButton from "../../img/qa-button.png";
import contactButton from "../../img/contact-button.png";
import moreButton from "../../img/more-button.png";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MainPageProps {}

const MainPage: FC<MainPageProps> = () => (
  <AppLayout>
    <div className={styles.imgContainer}>
      <img className="content" src={img1} alt="メインコンテンツ1" />
      <Link to="/takuji" className={styles.moreButton} style={{ top: "93%" }}>
        <img src={moreButton} alt="託児ページリンク" className="content" />
      </Link>
    </div>
    <div className={styles.imgContainer}>
      <img className="content" src={img2} alt="メインコンテンツ2" />
      <Link to="/meal" className={styles.moreButton} style={{ top: "15%" }}>
        <img src={moreButton} alt="食事ページリンク" className="content" />
      </Link>
      <Link to="/soudan" className={styles.moreButton} style={{ top: "36.5%" }}>
        <img src={moreButton} alt="育児相談ページリンク" className="content" />
      </Link>
      <Link to="/camera" className={styles.moreButton} style={{ top: "55%" }}>
        <img
          src={moreButton}
          alt="思い出づくりページリンク"
          className="content"
        />
      </Link>
      <Link
        to="/amenity"
        className={styles.moreButton}
        style={{ top: "74.5%" }}
      >
        <img src={moreButton} className="content" alt="備品ページリンク" />
      </Link>
    </div>
    <div className={styles.imgContainer}>
      <img className="content bg-image" src={img3} alt="メインコンテンツ3" />
      <Link to="/qa" className={styles.circleButton} style={{ left: "25%" }}>
        <img src={qaButton} className={`content`} alt="Q&Aリンク"></img>
      </Link>
      <Link
        to="/contact"
        className={styles.circleButton}
        style={{ left: "53.5%" }}
      >
        <img
          src={contactButton}
          className={`content`}
          alt="お問い合わせリンク"
        ></img>
      </Link>
    </div>
  </AppLayout>
);

export default MainPage;
