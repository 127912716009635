import {
  Path,
  RegisterOptions,
  UseFormReturn,
  FieldValues,
} from "react-hook-form";
import { useCallback } from "react";

export const useCustomRegister = <T extends FieldValues>(
  form: UseFormReturn<T>
) => {
  const { register, getFieldState } = form;

  const newRegister = useCallback(
    (name: Path<T>, options?: RegisterOptions<T>) => {
      const fieldState = getFieldState(name);
      return {
        ...register(name, options),
        isValid:
          !fieldState.invalid &&
          fieldState.isDirty &&
          fieldState.isTouched &&
          !options?.disabled,
        isInvalid: fieldState.invalid && !options?.disabled,
      };
    },
    // You need to append verbose variables to dependencies.
    // Because fieldstate depends them.
    // When you change code here, you must check dependency.
    // 1. Remove comment below temporary for checking dependency.(eslint-...)
    // 2. Do 'Quick fix'.
    // 3. Add verbose variables to dependencies.
    // Value: form.formState.errors, form.formState.dirtyFields, form.formState.touchedFields
    // 4. Add comment.(eslint-...)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getFieldState,
      register,
      form.formState.errors,
      form.formState.dirtyFields,
      form.formState.touchedFields,
    ]
  );
  return newRegister;
};
