import { useCallback, useMemo, useState } from "react";
import { Resource, Entity, StoredEntity } from "../types";
import { useActionStatus } from "./UseActionStatus";
import { v4 as uuidv4 } from "uuid";

const actions = ["save", "delete", "load"] as const;
const collectionActions = ["load"] as const;
const collectionKey = "collection";
const collectionKeys = [collectionKey];

export type UseEntitiesOptions<T, A extends readonly string[]> = {
  entities?: Entity<T>[];
  actions?: A;
};

export const normalize = <T>(entities: Entity<T>[]) => {
  const keys = Array<string>();
  const map: { [key: string]: StoredEntity<T> } = {};
  entities.forEach((entity) => {
    const key = entity.id ?? uuidv4();
    keys.push(key);
    map[key] = { ...entity, key };
  });

  return {
    keys,
    map,
  };
};

type loadOptions = {
  reload?: boolean;
};

export const useEntities = <T, A extends readonly string[]>(
  resource: Resource<T>,
  options?: UseEntitiesOptions<T, A>
) => {
  const { keys, map } = normalize(options?.entities ?? []);
  const [entityMap, setEntityMap] = useState<{
    [key: string]: StoredEntity<T>;
  }>(map);
  const [entityKeys, setEntityKeys] = useState<string[]>(keys);
  // const entityKeys = useMemo(() => entities.map((entity) => entity.key), [entities]);

  const {
    entitiesState: loadingStates,
    startAction: startLoading,
    finishAction: finishLoading,
  } = useActionStatus(collectionActions);
  const { entitiesState, startAction, finishAction } = useActionStatus(
    options?.actions ?? actions
  );

  const updateEntities = useCallback((entities: Entity<T>[]) => {
    const keys = Array<string>();
    const map: { [key: string]: StoredEntity<T> } = {};
    entities.forEach((entity) => {
      const key = entity.id ?? uuidv4();
      keys.push(key);
      map[key] = { ...entity, key };
    });
    setEntityKeys(keys);
    setEntityMap(map);
  }, []);

  const updateEntity = useCallback((key: string, entity: Entity<T>) => {
    setEntityMap((base) => ({ ...base, [key]: { ...entity, key } }));
  }, []);

  const loadAll = useCallback(async () => {
    startLoading(collectionKey, "load");

    try {
      updateEntities(await resource.loadAll());
      finishLoading(collectionKey, "load");
    } catch (e) {
      const error = resource.resolveError(e);
      finishLoading(collectionKey, "load", error);
      throw error;
    }
  }, [finishLoading, resource, startLoading, updateEntities]);

  const load = useCallback(
    async (id: string) => {
      startLoading(collectionKey, "load");

      try {
        updateEntities([{ id, value: await resource.load(id) }]);
        finishLoading(collectionKey, "load");
      } catch (e) {
        const error = resource.resolveError(e);
        finishLoading(collectionKey, "load", error);
        throw error;
      }
    },
    [finishLoading, resource, startLoading, updateEntities]
  );

  const save = useCallback(
    async (key: string, entity: T) => {
      const currentEntity = entityMap[key];
      if (!currentEntity) {
        throw new Error();
      }
      startAction(key, "save");

      try {
        if (currentEntity.id === undefined) {
          updateEntity(key, await resource.create(entity));
        } else {
          updateEntity(key, {
            id: currentEntity.id,
            value: await resource.update(currentEntity.id, entity),
          });
        }

        finishAction(key, "save");
      } catch (e) {
        finishAction(key, "save", resource.resolveError(e));
      }
    },
    [entityMap, finishAction, resource, startAction, updateEntity]
  );
  const saveAt = useCallback(
    async (index: number, entity: T) => {
      if (entityKeys.length <= index) {
        // どうしよ
        throw new Error();
      }
      return save(entityKeys[index], entity);
    },
    [entityKeys, save]
  );

  const del = useCallback(
    async (key: string) => {
      startAction(key, "delete");
      const currentEntity = entityMap[key];

      if (!currentEntity) {
        throw new Error();
      }
      if (currentEntity.id === undefined) {
        throw new Error();
      }

      try {
        await resource.delete(currentEntity.id);
        updateEntity(key, { ...currentEntity, deleted: true });
        finishAction(key, "delete");
      } catch (e) {
        finishAction(key, "delete", resource.resolveError(e));
      }
    },
    [entityMap, finishAction, resource, startAction, updateEntity]
  );
  const delAt = useCallback(
    async (index: number) => {
      if (entityKeys.length <= index) {
        // どうしよ
        throw new Error();
      }
      return del(entityKeys[index]);
    },
    [del, entityKeys]
  );
  const entities = useMemo(
    () => entityKeys.map((key) => entityMap[key]),
    [entityKeys, entityMap]
  );

  const loadAt = useCallback(
    (index: number | number[], options?: loadOptions) => {
      const indexList = Array.isArray(index) ? index : [index];
      indexList.forEach((index) => {
        if (!entities[index].value) {
          startLoading(entities[index].key, "load");
        }
      });
    },
    [entities, startLoading]
  );

  return useMemo(
    () => ({
      entityStates: entitiesState,
      loadingState: loadingStates[collectionKey],
      del,
      delAt,
      save,
      saveAt,
      load,
      loadAll,
      updateEntities,
      updateEntity,
      entities,
    }),
    [
      del,
      delAt,
      entities,
      entitiesState,
      load,
      loadAll,
      loadingStates,
      save,
      saveAt,
      updateEntities,
      updateEntity,
    ]
  );
};
