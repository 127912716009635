import React, { FC } from "react";
import "./CameraPage.scss";
import img1 from "../../img/4.png";
import AppLayout from "../../components/AppLayout/AppLayout";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CameraPageProps {}

const CameraPage: FC<CameraPageProps> = () => (
  <AppLayout>
    <img className="content" src={img1} alt="写真撮影コンテンツ" />
  </AppLayout>
);

export default CameraPage;
