import React, { FC, ReactNode } from "react";
import styles from "./AppLayout.module.scss";
import "./fade.scss";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { useFlex } from "../../common/UseFlex";
import { NavLink } from "react-router-dom";

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const visible = useFlex(true, 40, 10);

  return (
    <div>
      <CSSTransition in={visible} timeout={300} classNames="fade" unmountOnExit>
        <Navbar
          style={{
            position: "fixed",
            width: "100%",
            zIndex: 100,
            boxShadow: "none",
          }}
          expand="lg"
          className={styles.mainColor}
        >
          <Container>
            <Navbar.Brand as={NavLink} to="/">
              産後ケア 桃の花
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="/takuji">
                  お子様お預かり
                </Nav.Link>
                <Nav.Link as={NavLink} to="/meal">
                  お食事
                </Nav.Link>
                <Nav.Link as={NavLink} to="/soudan">
                  育児相談
                </Nav.Link>
                <Nav.Link as={NavLink} to="/camera">
                  思い出づくり
                </Nav.Link>
                <Nav.Link as={NavLink} to="/qa">
                  よくある質問
                </Nav.Link>
                <Nav.Link as={NavLink} to="/contact">
                  お問い合わせ
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </CSSTransition>
      <Row className={`justify-content-center ${styles.container}`}>
        <Col md={12} lg={8}>
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default AppLayout;
