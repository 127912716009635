import React, { FC } from "react";
import "./ContactPage.scss";
import AppLayout from "../../components/AppLayout/AppLayout";
import ContactForm from "../../article/components/ContactForm/ContactForm";
import { Container } from "react-bootstrap";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContactPageProps {}

const ContactPage: FC<ContactPageProps> = () => (
  <AppLayout>
    <Container className="mt-3">
      <ContactForm />
    </Container>
  </AppLayout>
);

export default ContactPage;
