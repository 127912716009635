import { useCallback, useMemo, useRef } from "react";
import { Resource } from "../types";
import { useEntities } from "./UseEntities";

const actions = ["load", "save", "delete"] as const;

export const useEntity = <T>(resource: Resource<T>) => {
  const {
    load: loadEntity,
    saveAt,
    delAt,
    entityStates,
    loadingState,
    updateEntities,
    entities,
  } = useEntities(resource, { entities: [{}], actions });
  const index = 0;
  const recentLoaded = useRef(false);

  const load = useCallback(
    async (id: string) => {
      recentLoaded.current = true;
      await loadEntity(id);
    },
    [loadEntity]
  );
  const newEntity = useCallback(
    (id?: string, value?: T) => {
      recentLoaded.current = false;
      updateEntities([{ id, value }]);
    },
    [updateEntities]
  );

  const save = useCallback(
    async (entity: T) => {
      recentLoaded.current = false;
      return await saveAt(index, entity);
    },
    [saveAt]
  );

  const del = useCallback(async () => {
    recentLoaded.current = false;
    return await delAt(index);
  }, [delAt]);

  const entity = useMemo(() => entities[index], [entities]);

  const entityState = useMemo(() => {
    const es = entityStates[entity.key];
    console.log(recentLoaded);
    console.log("Update entityState " + es?.action + " to " + es?.inProgress);
    return recentLoaded.current ? loadingState : es;
  }, [entity, entityStates, loadingState]);

  return useMemo(
    () => ({
      entityState,
      del,
      save,
      load,
      newEntity,
      entity,
    }),
    [del, entity, entityState, load, newEntity, save]
  );
};
