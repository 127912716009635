import React, { FC } from "react";
import "./MealPage.scss";
import AppLayout from "../../components/AppLayout/AppLayout";

import img1 from "../../img/2-1.png";
import img2 from "../../img/2-2.png";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MealPageProps {}

const MealPage: FC<MealPageProps> = () => (
  <AppLayout>
    <img className="content" src={img1} alt="食事コンテンツ1" />
    <img className="content" src={img2} alt="食事コンテンツ2" />
  </AppLayout>
);

export default MealPage;
