import { ActionError, Resource } from "./types";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { Firestore } from "firebase/firestore/lite";
import { yup } from "../vendor/yup.custom";

export class FirestoreResource<T extends yup.InferType<S>, S extends yup.Schema>
  implements Resource<T>
{
  private _db: Firestore;
  private _path: string;
  private _schema: S;

  public constructor(db: Firestore, path: string, schema: S) {
    this._db = db;
    this._path = path;
    this._schema = schema;
  }

  async load(path: string) {
    const docRef = doc(this._db, path);
    return this._schema.cast((await getDoc(docRef)).data());
  }
  async loadAll() {
    const collectionRef = collection(this._db, this._path);
    return (await getDocs(collectionRef)).docs.map((val) => ({
      value: this._schema.cast(val.data()),
      id: val.id,
    }));
  }
  async create(entity: T) {
    const collectionRef = collection(this._db, this._path);
    const result = await addDoc(collectionRef, entity);
    return {
      id: result.path,
      value: entity,
    };
  }
  async update(path: string, entity: T) {
    const docRef = doc(this._db, path);
    await setDoc(docRef, entity);
    return entity;
  }
  async delete(path: string) {
    const docRef = doc(this._db, path);
    await deleteDoc(docRef);
  }
  resolveError(e: unknown): ActionError {
    return {
      code: "network",
      message: "ネットワークを確認してください。",
    };
  }
}
