import { ActionError, FieldError } from "./types";

export const ensureIsActionError = (error: unknown): ActionError => {
  if (isActionError(error)) {
    return error;
  }

  return {
    code: "inner-exception",
    message: "予期せぬエラーが発生しました。",
    innerError: error instanceof Error ? error : undefined,
  };
};

export const isFieldError = (obj: unknown): obj is FieldError => {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  if (!("path" in obj) || typeof obj.path !== "string") {
    return false;
  }
  if (!("message" in obj) || typeof obj.message !== "string") {
    return false;
  }
  return true;
};

export const isActionError = (obj: unknown): obj is ActionError => {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  if (!("code" in obj) || typeof obj.code !== "string") {
    return false;
  }
  if (!("message" in obj) || typeof obj.message !== "string") {
    return false;
  }
  if ("fieldErrors" in obj) {
    if (!Array.isArray(obj.fieldErrors)) {
      return false;
    }
    for (const fieldError in obj.fieldErrors) {
      if (!isFieldError(fieldError)) {
        return false;
      }
    }
  }

  if ("innerError" in obj && !(obj.innerError instanceof Error)) {
    return false;
  }

  return true;
};
