// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore/lite";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCB4cmiWStPyz93vqNjE6Wd8_ri0nQgoCI",
  authDomain: "sangocare-a1290.firebaseapp.com",
  projectId: "sangocare-a1290",
  storageBucket: "sangocare-a1290.appspot.com",
  messagingSenderId: "65918592435",
  appId: "1:65918592435:web:5cb86099aa84542d4657c6",
  measurementId: "G-172BK7QJDF",
};

console.log(process.env.REACT_APP_APP_CHECK_DEBUG_TOKEN_FROM_CI);
globalThis.FIREBASE_APPCHECK_DEBUG_TOKEN =
  process.env.REACT_APP_APP_CHECK_DEBUG_TOKEN_FROM_CI;

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcJBx0nAAAAAMIpqfdLaFifFDIytFdTlNK7CD6O"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export const db = getFirestore(app);
