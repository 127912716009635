import React, { FC } from "react";
import "./SoudanPage.scss";
import AppLayout from "../../components/AppLayout/AppLayout";

import img1 from "../../img/3.png";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SoudanPageProps {}

const SoudanPage: FC<SoudanPageProps> = () => (
  <AppLayout>
    <img className="content" src={img1} alt="相談コンテンツ" />
  </AppLayout>
);

export default SoudanPage;
