import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useCustomRegister } from "../form/hooks/UseCustomRegister";
import { Contact, contactSchema } from "./types";

export type MessageFunction = <T>(label: string, params: T) => string;

const useContactForm = () => {
  const defaultValues = {} as Contact;
  const resolver = yupResolver(contactSchema);
  const form = useForm<Contact>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver,
  });
  const register = useCustomRegister(form);

  return {
    ...form,
    register,
  };
};

export { useContactForm };
