import React, { FC, useEffect } from "react";
import { useContactForm } from "../../UseContactForm";
import { useEntity } from "../../../entity/hooks/UseEntity";
import toast, { Toaster } from "react-hot-toast";
import { contactResource } from "../../types";
import { Button, Form } from "react-bootstrap";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContactFormProps {}

const ContactForm: FC<ContactFormProps> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useContactForm();

  const { entityState, newEntity, save } = useEntity(contactResource);

  console.log("Render");
  console.log(entityState);
  const onSubmit = handleSubmit(
    (values) =>
      toast.promise(
        save({
          template: {
            data: {
              content: values.template.data.content,
              name: values.template.data.name,
            },
            name: "contact",
          },
          to: values.to,
        }),
        {
          error:
            "お問い合わせ失敗しました。ネットワーク環境などをご確認ください。",
          loading: "お問い合わせ送信中...",
          success: "お問い合わせありがとうございます。",
        }
      ),
    () => console.log("Invalid")
  );

  useEffect(() => {
    newEntity();
  }, []);

  return (
    <div>
      <div>{entityState?.error?.message}</div>
      <Form onSubmit={onSubmit}>
        <fieldset disabled={entityState?.inProgress}>
          {/* お名前 */}
          <Form.Group controlId="name">
            <Form.Label>お名前</Form.Label>
            <Form.Control
              {...register("template.data.name")}
              placeholder="山田太郎"
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.template?.data?.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          {/* メールアドレス */}
          <Form.Group controlId="price">
            <Form.Label>メールアドレス</Form.Label>
            <Form.Control
              {...register("to")}
              placeholder="abcde@sample.co.jp"
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.to?.message}
            </Form.Control.Feedback>
          </Form.Group>
          {/* お問い合わせ内容 */}
          <Form.Group>
            <Form.Label>お問い合わせ内容</Form.Label>
            <Form.Control
              {...register("template.data.content")}
              as="textarea"
              rows={3}
            />
            <Form.Control.Feedback type="invalid">
              {errors.template?.data?.content?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <Button type="submit">送信</Button>
        </fieldset>
      </Form>
      <Toaster></Toaster>
    </div>
  );
};

export default ContactForm;
