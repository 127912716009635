import React from "react";
import "./App.css";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import MealPage from "./pages/MealPage/MealPage";
import CameraPage from "./pages/CameraPage/CameraPage";
import TakujiPage from "./pages/TakujiPage/TakujiPage";
import SoudanPage from "./pages/SoudanPage/SoudanPage";
import QaPage from "./pages/QaPage/QaPage";
import ContactPage from "./pages/ContactPage/ContactPage";

// 3️⃣ Router singleton created
const router = createBrowserRouter([{ path: "*", Component: Root }]);

// 4️⃣ RouterProvider added
export default function App() {
  return <RouterProvider router={router} />;
}

// 1️⃣ Changed from App to Root
function Root() {
  // 2️⃣ `BrowserRouter` component removed, but the <Routes>/<Route>
  // component below are unchanged
  return (
    <Routes>
      <Route path="/" element={<MainPage />}></Route>
      <Route path="/meal" element={<MealPage />}></Route>
      <Route path="/camera" element={<CameraPage />}></Route>
      <Route path="/takuji" element={<TakujiPage />}></Route>
      <Route path="/soudan" element={<SoudanPage />}></Route>
      <Route path="/qa" element={<QaPage />}></Route>
      <Route path="/contact" element={<ContactPage />}></Route>
    </Routes>
  );
}
