// import * as yup from "yup";
import { getFirestore } from "firebase/firestore";
import { FirestoreResource } from "../entity/FirestoreResource";
import { yup } from "../vendor/yup.custom";
import { app } from "../vendor/Firebase";
// import { descriptive } from "yup-locale-ja";

export const ContactMaxLength = 1000;
export const eitherRequired = (labels: string[]) => {
  return `${labels.join("と")}いずれか入力してください。`;
};
export const contactSchema = yup.object({
  to: yup.string().trim().required().email().max(254).label("メールアドレス"),
  template: yup
    .object({
      name: yup.string(),
      data: yup.object({
        name: yup.string().trim().required().max(30).label("お名前"),
        content: yup
          .string()
          .trim()
          .required()
          .max(ContactMaxLength)
          .label("お問い合わせ内容"),
      }),
    })
    .default(undefined),
});
export type Contact = yup.InferType<typeof contactSchema>;

export const contactResource = new FirestoreResource(
  getFirestore(app),
  "contacts",
  contactSchema
);
