import * as yup from "yup";
import { custom, ja } from "../locales/YupLocale.ja";
import moment from "moment";

declare module "yup" {
  interface StringSchema {
    time(this: StringSchema, format: string): StringSchema;
    phoneNumber(this: StringSchema): StringSchema;
  }
}

yup.addMethod(yup.StringSchema, "time", function (format: string) {
  return this.test({
    params: { format },
    test: (value: string | null) => {
      if (!value) return true;
      return moment(value, format, true).isValid();
    },
    name: "time",
    message: custom.time,
  });
});

yup.addMethod(yup.StringSchema, "phoneNumber", function () {
  return this.test({
    test: (value: string | null) => {
      if (!value) return true;
      return /^0\d{9,10}$/.test(value);
    },
    name: "phoneNumber",
    message: custom.phoneNumber,
  });
});

yup.setLocale(ja);

export { yup };
