import type * as yup from "yup";
import { typeLocale } from "./Locale.ja";
import { genJapaneseMessage } from "../utils/MessageUtils";

// NOTE: `notType` is omitted because it's too hard to users to understand the
//       concept and what's happening. Write your own if you need that one.
export const mixed: Required<yup.LocaleObject["mixed"]> = {
  default: ({ label }) => genJapaneseMessage(label, "の", "値が不正です"),
  required: ({ label }) => genJapaneseMessage(label, "は", "必須です"),
  oneOf: ({ label, values }) =>
    genJapaneseMessage(label, "には", `以下のものが入力できます: ${values}`),
  notOneOf: ({ label, values }) =>
    genJapaneseMessage(label, "には", `以下のものは入力できません: ${values}`),
  // From user's perspective, defined and default is essentially same.
  defined: ({ label }) => genJapaneseMessage(label, "は", "必須です"),
  notType: ({ label, type }) =>
    genJapaneseMessage(
      label,
      "には",
      `${typeLocale[type as keyof typeof typeLocale]}のみ入力できます。`
    ),
  notNull: ({ label }) => genJapaneseMessage(label, "は", "必須です"),
};

export const string: Required<yup.LocaleObject["string"]> = {
  length: ({ label, length }) =>
    genJapaneseMessage(label, "は", `${length}文字である必要があります`),
  min: ({ label, min }) =>
    genJapaneseMessage(label, "は", `${min}文字以上のみ入力できます`),
  max: ({ label, max }) =>
    genJapaneseMessage(label, "は", `${max}文字まで入力できます`),
  matches: ({ label, regex }) =>
    genJapaneseMessage(label, "は", `以下の形式である必要があります: ${regex}`),
  email: ({ label }) =>
    genJapaneseMessage(label, "は", "正しいメールアドレスではありません"),
  url: ({ label }) =>
    genJapaneseMessage(label, "は", "正しいURLではありません"),
  uuid: ({ label }) =>
    genJapaneseMessage(label, "は", "正しいUUIDではありません"),
  trim: ({ label }) =>
    genJapaneseMessage(label, "の", "前後に空白は含められません"),
  lowercase: ({ label }) =>
    genJapaneseMessage(label, "には", "小文字のみ入力できます"),
  uppercase: ({ label }) =>
    genJapaneseMessage(label, "には", "大文字のみ入力できます"),
};

export const number: Required<yup.LocaleObject["number"]> = {
  min: ({ label, min }) =>
    genJapaneseMessage(label, "には", `${min}以上の数のみ入力可能です`),
  max: ({ label, max }) =>
    genJapaneseMessage(label, "には", `${max}以下の数のみ入力可能です`),
  lessThan: ({ label, less }) =>
    genJapaneseMessage(label, "には", `${less}未満の数のみ入力可能です`),
  moreThan: ({ label, more }) =>
    genJapaneseMessage(label, "には", `${more}より大きい数のみ入力可能です`),
  positive: ({ label }) =>
    genJapaneseMessage(label, "には", "正の数のみ入力できます"),
  negative: ({ label }) =>
    genJapaneseMessage(label, "には", "負の数のみ入力できます"),
  integer: ({ label }) =>
    genJapaneseMessage(label, "には", "整数のみ入力可能です"),
};

// To format date, use the second argument of yup.date().min/max.
export const date: Required<yup.LocaleObject["date"]> = {
  min: ({ label, min }) =>
    genJapaneseMessage(label, "には", `${min}以降の日付のみ入力可能です`),
  max: ({ label, max }) =>
    genJapaneseMessage(label, "には", `${max}以前の日付のみ入力可能です`),
};

export const object: Required<yup.LocaleObject["object"]> = {
  noUnknown: ({ label, unknown }: { label?: string; unknown?: string }) =>
    genJapaneseMessage(
      label,
      "に",
      `予期せぬ項目が入力されました。${unknown ? `(${unknown})` : ""}`
    ),
};

export const array: Required<yup.LocaleObject["array"]> = {
  min: ({ label, min }) =>
    genJapaneseMessage(label, "は", `${min}つ以上必要です`),
  max: ({ label, max }) =>
    genJapaneseMessage(label, "は", `${max}つまで入力できます`),
  length: ({ label, length }) =>
    genJapaneseMessage(label, "は", `${length}つである必要があります`),
};

export const boolean: Required<yup.LocaleObject["boolean"]> = {
  isValue: ({ label, value }) =>
    genJapaneseMessage(
      label,
      "は",
      value ? "必ず選択する必要があります" : "選択できません"
    ),
};

// カスタムバリデーション用
export const custom = {
  time: (({ label, format }) =>
    genJapaneseMessage(
      label,
      "は",
      `時刻のみ入力できます(${format}形式)`
    )) as yup.Message,
  phoneNumber: (({ label }) =>
    genJapaneseMessage(
      label,
      "は",
      "ハイフンなしで国内電話番号(0から始まる10～11桁の数字)のみ入力できます。"
    )) as yup.Message,
} as const;

export const ja: Required<yup.LocaleObject> = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};
